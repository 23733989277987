import { graphql, Link } from 'gatsby';
import * as moment from 'moment';
import * as React from 'react';
import Helmet from 'react-helmet';
import { animated, Trail } from 'react-spring';
import * as styles from './Index.module.scss';

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      },
    },
    allMdx: {
      edges: [
        {
          node: {
            fields: {
              slug: string,
            },
            frontmatter: {
              title: string,
              date: string,
              images: [string],
              tagImage: string,
              tagDescription: string,
              tagUrl: string,
            },
          },
        }
      ],
    },
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            images
            tagImage
            tagDescription
            tagUrl
          }
        }
      }
    }
  }
`;

export default class IndexPage extends React.Component<IndexPageProps, {}> {

  public render() {
    const {
      name,
      tagline,
    } = this.props.data.site.siteMetadata;

    const title = <h1 key="title">{name}</h1>;
    const subtitle = <p key="tagline">{tagline}</p>;

    const footer = (
      <div key="Footer" className={styles.Footer}>
        <div className={styles.FooterMain}>
          "thecle" holds my collection of random photos: film and digital. Holding myself
          accountable for all the shots I take so there will be probably be some nice photos, some
          weird ones, some straight up bad ones, and hopefully some gems. Also, if it wasn't obvious,
          tap the photos to see more photos from that day :P.
        </div>
        <div className={styles.FooterSecondary}>
          My kit is 100% splurge but I like camera gear so why not ¯\_(ツ)_/¯. It consists of a
          Leica Q for digital and Zeiss Ikon ZM for film. Lenses are Zeiss 50mm Sonnar and Leica 35mm Summicron.
        </div>
        <div className={styles.FooterSecondary}>
          For those wondering, "cle" is the handle I chose for my first job after founding and leaving
          Outcomes.com. It just stuck and I was known as the "cle". I've since left, but I made
          amazing friends and learned a lot while at that company so "cle" has sentimental value to me.
        </div>
        <div className={styles.FooterSecondary}>
          I couldn't bother to update <a target="_blank" href="http://christianle.com">christianle.com</a> because
          rebuilding docker images and updating the CDN is a chore. If you're on a desktop or
          tablet, <a target="_blank" href="http://christianle.com">christianle.com</a> is probably more
          impressive from a web/software perspective (it was built and last updated 3 years ago though, so...).
        </div>
      </div>
    );

    const links = this.props.data.allMdx.edges.map((edge, index) => {
      const node = edge.node;

      const linkClass = `${styles.Link} ${
        index % 3 === 1 ? styles.LinkCenter : ''
      } ${
        index % 3 === 2 ? styles.LinkRight : ''
      }`;

      const galleryDate = moment(node.frontmatter.date, 'YYYY-MM-DD').format('MMM D, YYYY');
      const galleryTitle = node.frontmatter.title;

      return (
        <Link
          className={linkClass}
          key={node.fields.slug}
          to={node.fields.slug}
        >
          <div className={styles.LinkImageContainer}>
            <img
              className={styles.Image}
              src={node.frontmatter.images[0]}
              alt={`${galleryDate} - ${galleryTitle}`}
            />
            <div className={styles.Overlay} />
            <div className={styles.GalleryDescription}>
              <div className={styles.GalleryDate}>{galleryDate}</div>
              <div className={styles.GalleryTitle}>{galleryTitle}</div>
              <div className={styles.GalleryArrow}>&#8594;</div>
            </div>
          </div>
        </Link>
      );
    });

    const items = [title, subtitle].concat(links).concat([footer]);
    const keys = ['title', 'tagline'].concat(this.props.data.allMdx.edges.map((edge) => {
      return edge.node.fields.slug;
    })).concat(['Footer']);

    const tagTitle = 'cle\'s collection of photos';
    const tagDescription = 'random photos taken by cle';
    const tagImage = 'https://res.cloudinary.com/thecle/image/upload/v1546633919/404/2018-12-30_09.02.54.jpg';

    return (
      <div className={styles.Container}>
        <Helmet>
            {/* General tags */}
            <title>{tagTitle}</title>
            <meta name="description" content={tagDescription} />
            <meta name="image" content={tagImage} />

            {/* OpenGraph tags */}
            <meta property="og:url" content="https://thecle.me" />
            <meta property="og:title" content={tagTitle} />
            <meta property="og:description" content={tagDescription} />
            <meta property="og:image" content={tagImage} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@_christianle_" />
            <meta name="twitter:title" content={tagTitle} />
            <meta name="twitter:description" content={tagDescription} />
            <meta name="twitter:image" content={tagImage} />
        </Helmet>

        <Trail
          items={items}
          keys={keys}
          native={true}
          from={{ opacity: 0, transform: 'translateY(48px)' }}
          to={{ opacity: 1, transform: 'translateY(0px)' }}
        >
          {(item) => (props) => <animated.div style={props} className="item" children={item} />}
        </Trail>
      </div>
    );
  }
}
